<template>
  <div class="pixel-rule-list-table">
    <div v-if="loader && localPixelRules.length === 0" class="text-center">
      <Loader
        :size="'big'"
      />
    </div>
    <template v-if="selectedPixels.length < 1 && !loaderPixel && !loader">
      <!-- CARD -->
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr class="text-nowrap">
                  <th>{{ $t('pixel_name') }}</th>
                  <th>{{ $t('rule_name') }}</th>
                  <th>{{ $t('creation_date') }}</th>
                  <th>{{ $t('objective') }}</th>
                  <th>{{ $t('event_name') }}</th>
                  <th>{{ $t('status') }}</th>
                  <th>{{ $t('pixel_code') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(rule, index) in localPixelRules" :key="index"  class="text-nowrap">
                  <td>
                    <router-link :to="{ name: 'pixels.show', params: { id: rule.pixel.id } }" class="link-color3">
                      {{ rule.pixel.name }}
                    </router-link>
                  </td>
                  <td>{{ rule.name }}</td>
                  <td>{{ rule.created_at | formatDate }}</td>
                  <td>{{ rule.objective | formatObjective }}</td>
                  <td>{{ rule.event_name }}</td>
                  <td>
                    {{ rule.status | formatStatus }}
                  </td>
                  <td>
                    <template v-if="platform">
                      <a
                        href="#"
                        @click.prevent="openPixelRuleCodeModal(rule.pixel, rule)"
                        class="link-color2"
                        :class="{
                          'pending': rule.pixel.status === 'PENDING'
                        }"
                        :id="`rule-${rule.id}`"
                      >
                        <i class="fa fa-code"></i>
                      </a>
                      <b-tooltip
                        :target="`rule-${rule.id}`"
                        :title="`${rule.status === 'PENDING' ? $t('pixel_creation_can_take_up_to_48_hours') : $t('copy_code') }`"
                        container="body"
                        v-if="rule.status === 'PENDING'"
                      ></b-tooltip>
                    </template>
                    <template v-else>
                      <a
                        href="#"
                        @click.prevent=""
                        class="link-color2"
                        :class="{
                          'pending': true
                        }"
                        :id="`rule-${rule.id}`"
                      >
                        <i class="fa fa-code"></i>
                      </a>
                      <b-tooltip
                        :target="`rule-${rule.id}`"
                        :title="`${rule.status === 'PENDING' ? $t('pixel_creation_can_take_up_to_48_hours') : $t('to_access_the_pixel_code')}`"
                        container="body"
                      ></b-tooltip>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
            <PixelRuleCodeModal
              :pixel="selectedPixel"
              :platform="platform"
              :rule="selectedPixelRule"
              :advertiser="advertiser"
            />
          </div>
          <div class="text-center mt-4">
            <Button
              class="btn-primary btn-sm"
              :loading="loader"
              @click="loadMore"
              v-if="isLoadMoreVisible"
            >
              {{ $t('load_more') }}
            </Button>
          </div>
        </div>
      </div> <!-- END CARD -->
    </template>
    <div v-if="selectedPixels.length > 0">
      <template>
        <table class="table table-striped">
          <thead>
            <tr>
              <th>{{ $t('pixel_name') }}</th>
              <th>{{ $t('rule_name') }}</th>
              <th>{{ $t('objective') }}</th>
              <th>{{ $t('event_name') }}</th>
              <th width="100">{{ $t('actions') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-nowrap" v-for="(rule, index) in selectedPixels" :key="index">
              <td>{{ rule.pixel.name }}</td>
              <td>{{ rule.name }}</td>
              <td>{{ rule.objective | formatObjective }}</td>
              <td>{{ rule.event_name }}</td>
              <td>
                <Button
                  class="btn-danger btn-xs font-size-small btn-circle p-0 mb-2"
                  @click.prevent="openDestroyPixelRuleModal(rule)"
                  v-b-tooltip.hover :title="$t('remove_rule')"
                >
                  <i class="fa fa-trash"></i>
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
      <div class="text-center">
        <Button
          class="btn btn-color1 mb-4 btn-sm"
          @click.prevent="associatePixel"
          :loading="loaderPixel"
        >
          {{ $t('add') }}
        </Button>
      </div>
    </div>
    <SelectPixelRuleModal
      :advertiserId="advertiser.id"
      @pixel-rule-selected="handleSelectedPixelRule"
    />
    <DestroyPixelRuleModal
      :rule="selectedPixelRule"
      @pixel-rule-destroyed="destroyPixelRule"
    />
</div>
</template>

<script>

// import PixelsService from '@/modules/pixels/services/pixels-service'

import NotificationService from '@/modules/notifications/services/notifications-service'
import CampaignsService from '@/modules/campaigns/services/campaigns-service'

import Button from '@/components/common/Button/Button'
import DestroyPixelRuleModal from '@/components/campaigns/UpsertForm/DestroyPixelRuleModal'
import Loader from '@/components/common/Loader/Loader'
import PixelRuleCodeModal from '@/components/pixels/PixelRuleCodeModal'
import SelectPixelRuleModal from '@/components/pixels/SelectPixelRuleModal'

export default {
  props: ['advertiser', 'baseCampaign', 'getCampaign', 'rules', 'platform'],
  name: 'PixelRuleListTable',
  components: {
    Button,
    DestroyPixelRuleModal,
    Loader,
    PixelRuleCodeModal,
    SelectPixelRuleModal
  },
  data () {
    return {
      campaign: null,
      isLoadMoreVisible: false,
      loader: false,
      localPixelRules: [],
      loaderPixel: false,
      selectedPixels: [],
      page: 1,
      selectedPixel: null,
      selectedPixelRule: null
    }
  },
  created () {
    if (this.baseCampaign) this.campaign = this.baseCampaign
    if (this.rules) this.localPixelRules = this.rules
  },
  watch: {
    rules (newValue, oldValue) {
      this.localPixelRules = newValue
    }
  },
  methods: {
    /**
     * Associate pixel to campaign
     */
    async associatePixel () {
      this.loaderPixel = true
      try {
        const existingIds = this.campaign.pixels.map(item => item.id)
        const newIds = this.selectedPixels.map(item => item.id)

        const filteredNewIds = newIds.filter(id => !existingIds.includes(id))
        const pixels = [...existingIds, ...filteredNewIds]

        await CampaignsService.associatePixel(this.campaign.id, pixels)
        const notification = {
          notification: {
            type: 'success',
            content: this.$t('pixel_associate_successfully')
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.selectedPixels = []
      this.getCampaign()
      this.loaderPixel = false
    },
    /**
     * Remove pixel rule from rules array
     */
    destroyPixelRule (rule) {
      const index = this.localPixelRules.findIndex(item => {
        return item.id === rule.id
      })
      this.selectedPixels.splice(index, 1)
    },
    /**
     * Handle selected pixel rule
     */
    handleSelectedPixelRule (rule) {
      const index = this.selectedPixels.findIndex(item => {
        return item.id === rule.id
      })
      if (index >= 0) return
      this.selectedPixels.push(rule)
    },
    /**
     * Open pixel rule code modal
     */
    openPixelRuleCodeModal (pixel, rule) {
      if (rule.status === 'PENDING') return
      this.selectedPixel = pixel
      this.selectedPixelRule = rule
      this.$bvModal.show('pixel-rule-code-modal')
    },
    /**
     * Open modal to destroy pixel rule
     */
    openDestroyPixelRuleModal (rule) {
      this.selectedPixelRule = rule
      this.$bvModal.show('destroy-pixel-rule-modal')
    }
  }
}
</script>

<style lang="scss" scoped>
a.pending {
  opacity: 0.5;
}
</style>

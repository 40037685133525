<template>
  <b-modal
    :id="modalId"
    :modal-class="'default-modal'"
    :hide-footer="true"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>{{ rule ? rule.name : '' }}</span>
      </h2>
    </div>
    <div class="mt-4" v-if="rule && advertiser">
      <!-- YAHOO -->
      <template v-if="platform === 'YAHOO'">
        <h3>Instruções</h3>
        <p>Place this tag on the page of your website you want to track.</p>
        <ul>
          <li>Copy and paste the below code inside the &lt;head&gt; tag, on all pages of your website you want to track.</li>
          <li>To verify that the tag is working correctly, go to the Tag List view and you should see the number of users who visited your page.</li>
          <li>You can now start using your Conversion/Retargeting rules in your Campaigns.</li>
        </ul>
        <code class="mb-4">
          &lt;script type="application/javascript"&gt;(function(w,d,t,r,u){w[u]=w[u]||[];w[u].push({'projectId':'10000','properties':{'pixelId':'{{rule.pixel.yahoo_id}}','he': '&lt;email_address&gt;','auid': '&lt;sha256_hashed_user_id&gt;'}});var s=d.createElement(t);s.src=r;s.async=true;s.onload=s.onreadystatechange=function(){var y,rs=this.readyState,c=w[u];if(rs&&rs!="complete"&&rs!="loaded"){return}try{y=YAHOO.ywa.I13N.fireBeacon;w[u]=[];w[u].push=function(p){y([p])};y(c)}catch(e){}};var scr=d.getElementsByTagName(t)[0],par=scr.parentNode;par.insertBefore(s,scr)})(window,document,"script","https://s.yimg.com/wi/ytc.js","dotq");&lt;/script&gt;
        </code>
        <p>Place this tag on the page of your website you want to track.</p>
        <ul>
          <li>Copy and paste the below code inside the &lt;head&gt; tag, on the page ou action (button, form) of your website you want to track.</li>
          <li>Replace the {INSERT_MACRO_HERE} placeholder value in this tag</li>
          <li>To verify that the tag is working correctly, go to the Tag List view and you should see the number of users who visited your page.</li>
          <li>You can now start using your Conversion/Retargeting rules in your Campaigns.</li>
        </ul>
        <code>
          &lt;img src="https://sp.analytics.yahoo.com/spp.pl?a=10000&.yp={{ rule.pixel.yahoo_id }}&he={{ rule.event_name }}&auid={{ rule.event_name }}&ea={{ rule.event_name }}"/&gt;
        </code>
      </template>
      <!-- GOOGLE -->
      <template v-else-if="platform === 'GOOGLE'">
        <h3>{{ $t('insertion_instructions_via_GTM_or_wix') }}</h3>
        <p>{{ $t('info_instructions_via_GTM_or_wix') }} <a href="https://pixelroads.com.br/blog/como-instalar-um-pixel-com-gtm-google-tag-manager-usando-floodlight-codigos" target="_blank">{{ $t('click_here') }}</a> {{ $t('info_instructions_via_GTM_or_wix2') }}</p>
        <p>
          <strong>Floodlight ID:</strong> {{ rule.pixel.google_id }}<br/>
          <strong>Activity Group Tag String:</strong> invmedia<br/>
          <strong>Activity Tag String:</strong> {{ rule.google_activity_tag }}
        </p>
      </template> <!-- END GOOGLE -->
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'PixelRuleCodeModal',
  props: ['advertiser', 'pixel', 'platform', 'rule'],
  computed: {
    modalId () {
      if (this.rule) return 'pixel-rule-code-modal'
      else return 'pixel-rule-code-modal'
    }
  }
}
</script>

<style lang="scss" scoped>
code {
  background-color: #f1f1f1;
  display: block;
  padding: 15px;
}
</style>
